
import axios from "@/axios.config.js";

const getUser = ({page = 1, limit = 15, query = "", order = "", asc = 0}) => axios.get(`/api/admin/v1/users`,{
  params:{
    page: page,
    limit: limit,
    q: query,
    order: order.length ? order : null,
    asc
  }
})

const updateUser = (userId,body) => axios.put(`/api/admin/v1/users/${userId}`,body);

const exportUser = ({asc = 0, order = "", q = ""}) => axios.post(`/api/admin/v1/users/export`,null,{
  params:{
    q,
    order,
    asc
  },
  responseType: 'blob'
})

const referralsIndex = (userId) => axios.get(`/api/admin/v1/users/${userId}/referrals`)

const leaderboardPersonal = ({userId, page = 1, limit = 15, order = "DATE", asc = 0, q = ""}) => axios.get(`/api/admin/v1/users/${userId}/leaderboardPersonal`,{
  params:{
    page,
    limit,
    order,
    asc,
    q
  }
})

export{
  getUser,
  updateUser,
  exportUser,
  referralsIndex,
  leaderboardPersonal
}